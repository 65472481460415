<template>
  <SelectValue
    title="Action"
    @input="onInput"
    v-model="val"
    :className="className"
    :readonly="readonly"
    :options="options"
  >
  </SelectValue>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "SelectArea",
  props: {
    value: String,
    className: String,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      val: "",
    };
  },
  computed: {
    options() {
      return [
        {
          value: "open_modal",
          text: "Open modal in Dashboard",
        },
        {
          value: "open_url",
          text: "Open as URL",
        },
      ];
    },
  },
  methods: {
    onInput(e) {
      this.$emit("input", this.val);
      this.$emit("change", this.val);
    },
  },
  created() {
    this.val = this.value;
  },
  watch: {
    value(newV) {
      this.val = newV;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes spinning {
  from {
    transform: rotate(14deg);
  }
  to {
    transform: rotate(373deg);
  }
}
.reroll {
  position: absolute;
  top: 25%;
  right: 15px;
  color: #777;
  svg {
    transform: rotate(14deg);
  }
  &:hover {
    color: #ff4757;
    cursor: pointer;
  }
  &.spinning {
    svg {
      animation: spinning 1000ms linear 0s alternate;
    }
  }
}
</style>
